import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "./utils/supabaseClient";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

function BarangKeluarForm() {
  const navigate = useNavigate();
  const [barangOptions, setBarangOptions] = useState([]);
  const [selectedBarang, setSelectedBarang] = useState(null); // Menyimpan objek barang
  const [quantity, setQuantity] = useState("");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [error, setError] = useState("");
  const [scannedData, setScannedData] = useState("");

  const session = JSON.parse(localStorage.getItem("session"));
  const userId = session?.user_id;

  useEffect(() => {
    const fetchBarangOptions = async () => {
      const { data, error } = await supabase
        .from("barang")
        .select("barang_id, nama, barang_id_barcode");

      if (error) {
        console.error("Error fetching barang:", error);
      } else {
        setBarangOptions(data);
      }
    };
    fetchBarangOptions();
  }, []);

  const handleScan = async (result) => {
    if (result) {
      const scannedId = parseInt(result.text, 10);
      setScannedData(scannedId);

      const { data, error } = await supabase
        .from("barang")
        .select("*")
        .eq("barang_id_barcode", scannedId)
        .single();

      if (data) {
        setSelectedBarang(data); // Simpan seluruh objek barang
        setError("");
      } else {
        setError(`Barang dengan barcode ${scannedId} tidak ditemukan.`);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedBarang || !quantity || quantity <= 0) {
      setError("Please fill in all fields with valid data");
      return;
    }

    try {
      const newQuantity = selectedBarang.kuantitas - quantity;
      if (newQuantity < 0) {
        setError("Insufficient stock for this item");
        return;
      }

      const { error: updateError } = await supabase
        .from("barang")
        .update({ kuantitas: newQuantity })
        .eq("barang_id", selectedBarang.barang_id);
      if (updateError) throw new Error("Failed to update barang data");

      const { error: insertError } = await supabase.from("log").insert([
        {
          user_id: userId,
          barang_id: selectedBarang.barang_id,
          aksi: "keluar",
          kuantitas_berubah: quantity,
          tanggal: date,
        },
      ]);
      if (insertError) throw new Error("Failed to insert log data");

      navigate("/barang-keluar");
    } catch (err) {
      setError(err.message || "An unexpected error occurred");
    }
  };

  return (
    <div className="bg-gray-300 flex flex-col items-center min-h-screen">
      <div className="flex flex-col w-full sm:max-w-sm bg-white h-screen pt-8 px-4 overflow-y-auto">
        <div className="border-b pb-4">
          <a href="/barang-keluar">Back</a>
        </div>
        <p className="text-2xl mt-4">Barang Keluar</p>
        <BarcodeScannerComponent
          width={500}
          height={500}
          onUpdate={(err, result) => handleScan(result)}
        />
        <form onSubmit={handleSubmit} className="mt-4">
          <div className="flex flex-col gap-2">
            <label htmlFor="barcode">Barcode Produk</label>
            <input
              type="text"
              placeholder="Scan barcode atau pilih produk"
              className="input input-bordered disabled"
              value={selectedBarang?.barang_id_barcode || "Pilih Barang dahulu"}
              disabled
            />
          </div>

          <div className="flex flex-col gap-2">
            <label htmlFor="produk">Pilih Produk</label>
            <select
              className="select select-bordered"
              value={selectedBarang?.barang_id || ""}
              onChange={(e) => {
                const selected = barangOptions.find(
                  (barang) => barang.barang_id === parseInt(e.target.value, 10)
                );
                setSelectedBarang(selected || null);
              }}
              required
            >
              <option disabled value="">
                Pilih produk...
              </option>
              {barangOptions.map((barang) => (
                <option key={barang.barang_id} value={barang.barang_id}>
                  {barang.nama}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="quantity">Pilih Quantity</label>
            <input
              type="number"
              className="input input-bordered"
              value={quantity}
              onChange={(e) => setQuantity(parseInt(e.target.value, 10) || "")}
              required
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="date">Pilih Date</label>
            <input
              type="date"
              className="input input-bordered"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
          {error && <p className="text-red-500">{error}</p>}
          <button className="btn btn-primary w-full mt-2" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default BarangKeluarForm;
