import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "./utils/supabaseClient";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

function BarangMasukForm() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [barcode, setBarcode] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [scannedData, setScannedData] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      const { data, error } = await supabase
        .from("barang")
        .select("barang_id, nama, barang_id_barcode");
      if (error) {
        console.error("Error fetching products:", error);
      } else {
        setProducts(data);
      }
    };
    fetchProducts();
  }, []);

  const handleScan = async (result) => {
    if (result) {
      const scannedId = parseInt(result.text, 10);

      const { data, error } = await supabase
        .from("barang")
        .select("*")
        .eq("barang_id_barcode", scannedId)
        .single();

      if (data) {
        setSelectedProduct(data); // Simpan seluruh objek barang
        setError(null);
      } else {
        setError(`Barang dengan barcode ${scannedId} tidak ditemukan.`);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedProduct || quantity <= 0 || !date) {
      setError("Harap isi semua kolom dengan benar.");
      return;
    }

    const { data: barangData, error: fetchError } = await supabase
      .from("barang")
      .select("kuantitas")
      .eq("barang_id", selectedProduct.barang_id)
      .single();

    if (fetchError) {
      setError("Gagal mengambil data barang.");
      return;
    }

    const newQuantity = barangData.kuantitas + parseInt(quantity, 10);

    const { error: updateError } = await supabase
      .from("barang")
      .update({ kuantitas: newQuantity })
      .eq("barang_id", selectedProduct.barang_id);

    if (updateError) {
      setError("Gagal memperbarui data barang.");
      return;
    }

    const { error: insertError } = await supabase.from("log").insert([
      {
        user_id: 1, // Ganti dengan ID pengguna sebenarnya
        barang_id: selectedProduct.barang_id,
        aksi: "masuk",
        kuantitas_berubah: quantity,
        tanggal: date,
      },
    ]);

    if (insertError) {
      setError("Gagal memasukkan data log.");
    } else {
      navigate("/barang-masuk");
    }
  };

  return (
    <div className="bg-gray-300 flex flex-col items-center min-h-screen">
      <div className="flex flex-col w-full sm:max-w-sm bg-white h-screen pt-8 px-4 overflow-y-auto">
        <div className="border-b pb-4">
          <a href="/barang-masuk">Back</a>
        </div>
        <div className="flex flex-col mt-4">
          <p className="text-2xl">Barang Masuk</p>
        </div>
        <BarcodeScannerComponent
          width={500}
          height={500}
          onUpdate={(err, result) => handleScan(result)}
        />

        <form onSubmit={handleSubmit} className="mt-4">
          <div className="flex flex-col gap-2">
            <label htmlFor="barcode">Barcode Produk</label>
            <input
              type="text"
              placeholder="Scan barcode atau pilih produk"
              className="input input-bordered disabled"
              value={
                selectedProduct?.barang_id_barcode || "Pilih Barang dahulu"
              }
              disabled
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="produk">Pilih Produk</label>
            <select
              className="select select-bordered"
              value={selectedProduct?.barang_id || ""}
              onChange={(e) => {
                const selected = products.find(
                  (barang) => barang.barang_id === parseInt(e.target.value, 10)
                );
                setSelectedProduct(selected || null);
              }}
              required
            >
              <option disabled value="">
                Pilih produk...
              </option>
              {products.map((barang) => (
                <option key={barang.barang_id} value={barang.barang_id}>
                  {barang.nama}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="quantity">Pilih Quantity</label>
            <input
              type="number"
              className="input input-bordered"
              placeholder="Tambah Quantity"
              value={quantity}
              onChange={(e) => setQuantity(parseInt(e.target.value))}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="date">Pilih Date</label>
            <input
              type="date"
              className="input input-bordered"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
          <button className="btn btn-primary w-full mt-2" type="submit">
            Submit
          </button>
        </form>
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </div>
    </div>
  );
}

export default BarangMasukForm;
